<template>
  <v-app id="app">
    <v-main>
      <div class="navigation">
        <!-- v-if문 -->
        <div v-if="TokenUser.authorization == 'admin'">
          <Navbar></Navbar>
        </div>
        <div v-else></div>
      </div>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import Navbar from '../src/components/NavBar.vue'

export default {
  
  name: 'App',
  components:{
    Navbar
  },
    data(){
      return {
        TokenUser : {
          authorization : localStorage.getItem('verify')
        }
      }
    }
};
</script>
<style >
.navigation {
  margin: 2% 0 0 80%; 
}

</style>
