import axios from 'axios'
import router from '../router'

const DOMAIN = 'https://api.isaac-research.com'

const UNAUTHORIZED = 401
const API_KEY = process.env.API_KEY
const onUnauthorized = () => {
  router.push('/login')
}

export const request = (method, url, data) => {
  return axios({
    method,
    url: DOMAIN + url,
    data,
    headers:{
      "Content-Type": "application/json",
      "x-api-key": API_KEY
    }
  })
    .then(result => {
      return result
    })
    .catch(result => {
      const { status } = result.response
      if (status === UNAUTHORIZED) return onUnauthorized()
      throw Error(result)
    })
}