import { auth } from '../api/auth'
import { setAuthInHeader } from '../api/auth'


export const Auth = {
  namespaced: true,

  state:{
    not : null
  },
  mutations:{
    LOGIN(state,data) {
      // if(!data.token) return
      this.state.not = data.token
      // console.log('state.auth : ' ,data.data.token);
      localStorage.setItem('token',data.token)
      localStorage.setItem('verify',data.verify)
      setAuthInHeader(data.token)
      
    },
    LOGOUT(state,data){
      state.TokenUser.authorization = data
      localStorage.removeItem('token')
    }
  },
  actions:{
    LOGIN_AUTH({commit}, {id, password}) {
      return auth
      .login(id,password)
      .then(data => {
        commit('LOGIN',data.data)
      })
      .catch((error => {
        console.log(error);
      }))
    },
    CHECK_VERIFY({commit},{token,path}){
      if( path == 'home'){
        return auth
        .check_token(token)
        .then(
          data => {
            console.log(data);
            location.href = '/'
          }
        ) .catch(error => {
          console.log('error',error);
          location.href = '/login'
          localStorage.removeItem('verify')
          alert('유효하지 않은 token입니다. 다시 로그인해주세요')
          
        })
      } else if ( path == 'setting'){
        return auth
        .check_token(token)
        .then(
          data => {
            console.log(data);
            location.href = '/setting'
          }
        ) .catch(error => {
          console.log('error',error);
          location.href = '/login'
          localStorage.removeItem('verify')
          alert('유효하지 않은 token입니다. 다시 로그인해주세요')
        })
      }


    }
  }
}