import Vue from "vue";
import Vuex from "vuex";

import {Auth} from './Auth'
import { Data } from "./Data";
import { setAuthInHeader } from "../api/auth";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules:{
    Auth: Auth,
    Data: Data
  },
  plugins: [createPersistedState()],
  state:{
    token:localStorage.getItem('token') || null
  },
  mutations:{
    LOGINED(state,token){
      if(!token) return
      state.token = token
      localStorage.setItem('token',token)
      setAuthInHeader(token)
    },
    LOGOUTED(state,token){
      if(!token) return
      localStorage.removeItem('token')
    }
  }

})


const {token} = localStorage
store.commit('LOGINED',token)


export default store
