import { request } from "./index";
import axios from 'axios'

export const auth = {
  login(id, password) {
    return request('post','/login',{ id, password})
  },
  check_token(token) {
    return request('post','/verify',{token})
  }
}

export const setAuthInHeader = token => {
  axios.defaults.headers.common['Authorization'] = token ? `${token}` : null
}