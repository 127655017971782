import {data} from '../api/data'





export const Data = {
  namespaced: true,

  state : {
    DataList : []
  },
  getters:{
    DataList: state => state.DataList
  },
  mutations:{
    GET_DATALIST(state,data){
      state.DataList = data
    }
  },
  actions:{
    GET_DATALIST_ACTIONS({commit}, {startdate, enddate, machinenumber}) {
      return data
        .getData(startdate, enddate, machinenumber)
        .then( Data => {
          const result = Data.data.data.TestResult
          console.log(Data.data.data);
          console.log('first',result);
          const arr = []
          for(let i=0; i<result.length; i++){
            let obj = {}
            delete result[i].id
            var date = new Date(result[i].createAt)
            //date.setHours(date.getHours()+9)
            result[i].number = i + 1
            obj.number = i + 1
            obj.time = date
            obj.T1 = result[i].T1
            obj.T2 = result[i].T2
            obj.T3 = result[i].T3
            obj.T4 = result[i].T4
            obj.T5 = result[i].T5
            obj.T9 = result[i].T9
            obj.T10 = result[i].T10
            obj.T11 = result[i].T11
            obj.T12 = result[i].T12
            obj.T13 = result[i].T13
            obj.pump = result[i].pump
            obj.heater = result[i].heater
            obj.sole_noid = result[i].sole_noid
            obj.flowrate = result[i].flowrate
            obj.drain = result[i].drain
            obj.solarRadiat = result[i].solarRadiat
            obj.temperature = result[i].Temperature
            arr.push(obj)

          }
          commit('GET_DATALIST', arr)
        })
        .catch( error => {
          console.log('(store actions) GET DATA FAIL : ', error);
        })
    }
  }
}