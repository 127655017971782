<template>
  <div class='navBox'>
    <div class="navButton" >
      <div @click="routingToHome">
        <v-btn text>
          Home
        </v-btn>
      </div>

    </div>
    <div class="navButton" @click="routingToSetting">
      <v-btn text>
        Setting
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
  export default {
    methods:{
      ...mapActions('Auth',['CHECK_VERIFY']),
      async routingToSetting() {
        await this.CHECK_VERIFY({token:localStorage.getItem('token'),path:'setting'})
      },
      async routingToHome(){
        await this.CHECK_VERIFY({token:localStorage.getItem('token'),path:'home'})
      }
    }
  }
</script>

<style>
.navBox {
  display: flex;
}
.navButton {
  margin-left: 10%;
}

</style>